import React from "react";
import { Link } from "gatsby";

import SEO from "../components/SEO";
import CenteredPageSection from "../components/CenteredPageSection";

import error_404_banner from "../images/404-banner.jpg";

const NotFoundPage: React.FC = () => {
  return (
    <>
      <SEO title="404: Page Not found" />
      <div
        className={`w-full h-32 lg:h-80 flex flex-col justify-center align-middle text-center text-white bg-top bg-cover`}
        style={{ backgroundImage: `url(${error_404_banner}` }}
      >
        <h1>Page Not Found</h1>
      </div>
      <CenteredPageSection classNames="flex md:justify-center md:align-center">
        <div className="md:w-2/3">
          <p>
            We&apos;re sorry, but the page that you&apos;re looking for has
            either been removed or relocated.
          </p>
          <p>
            You can return to our{" "}
            <Link className="text-clhbid-orange" to="/">
              homepage
            </Link>{" "}
            or, if you can&apos;t find what you&apos;re looking for, please feel
            free to{" "}
            <Link className="text-clhbid-orange" to="/contact">
              contact us
            </Link>
            .
          </p>
        </div>
      </CenteredPageSection>
    </>
  );
};

export default NotFoundPage;
